// Here you can add other styles
:root { scroll-behavior: auto; } // for dnd-kit fast scroll in backlogs

body {
  margin: 0;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
}

.loading {
  position: relative;
  overflow: hidden;
  background-color: #EFEFEF;
}

.loading::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #EFEFEF, rgba(255,255,255,.8), #EFEFEF);
  animation: Gradient 1500ms ease-in-out infinite;
}

@keyframes Gradient {
  0% {
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    transform: translate3d(100%, 0, 0);
  }
}

/* ----------------- DOODADS ----------------- */

.text-header {
  color: #1565c0;
}

.text-header a {
  color: #1565c0;
  vertical-align: text-top;
}

.text-muted-alt {
  color: #23282c;
}

.form-control.is-valid, .form-control.is-invalid {
  background-image: none;
}

a:hover {
}

.tooltip-custom-position {
  // left: 120px !important;
  // top: -10px !important;
  // width: 200px;
  // z-index: 10000;
}

.badge-status {
  width: 16px;
  height: 16px;
  line-height: 10px;
  padding-right: 0.5em;
  padding-left: 0.5em;
}

.expand-plus {
  padding: 3px;
  width: 20px !important;
  height: 20px;
}

.filter-card {
  font-size: 0.75rem;
  z-index: 2;
}

.filter-card .btn {
  font-size: 0.75rem;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.pagination .btn {
  min-width: 31px;
}

.pagination .disabled .btn {
  opacity: 0.65;
  color: #4695F5;
  background-color: transparent;
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

@media only screen and (max-width: 768px) {
  .flex-grow-calc-adjust {
    height: auto !important;
  }
}

.margin-checkbox {
  margin: -4px 1px;
}

.toastr {
  position: fixed !important;
  top: 50px;
  right: 4px;
  z-index: 99999;
}
.nodata-table {
  position: absolute;
    width: 100%;
    text-align: center;
    background: white;
    color: #877979;
    height: 30px;
    top:40%;
}

.tooltip-custom {
  margin-left: -20px !important;
}

.tooltip-question {
  padding: 1.750px 0px !important;
  font-size: 0.75rem;
}

.tooltip {
  background-color: #707070 !important;
  opacity: 1 !important;
}

.tooltip-timeline {
  box-shadow: 5px 5px 10px 1px #888888;
  width: 16rem;
}

.tooltip::before, .tooltip::after {
  border-right-color: #707070 !important;
  border-left-color: #707070 !important;
}

.fa-rotate-45 {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

*:focus, *:hover {
  outline: none !important;
}

.react-tel-input .form-control {
  font-size: 0.875rem !important;
  display: block;
  width: 100% !important;
  height: calc(1.5em + 0.75rem + 2px);
  border: 1px solid #e4e7ea !important;
  padding: 0 0 0 4rem !important;
}

.react-tel-input .form-control.is-invalid {
  border-color: #D21616 !important;
}

.bs-tooltip-auto {
  background-color: transparent !important;
}

.bs-tooltip-auto .tooltip-inner {
  background-color: #393E49 !important;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #393E49 !important;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #393E49 !important;
}

.bg-muted {
  background-color: #DDDDDD;
}

.bg-custom-gray {
  background-color: #EBEBEB;
}

.dragged.hovered ~ .dl-item.floating {
  box-shadow: 0px 0px 5px rgba(0,0,0,.2);
  left: 0px !important;
}

#floorplan .canvas-container {
  // display: table-cell;
}

#floorplan .canvas-container, #floorplanZone .canvas-container {
  margin: auto;
}

#floorplanZone > div {
  display: 'none' !important;
}

.floorplan-popover.popover {
  max-width: 300px !important;
}
.facing-tooltip.popover {
  width: 180px !important;
}
.facing-popover.popover  {
  width: 230px !important;
}
.sensor-popover.popover  {
  width: 250px !important;
}

.floorplan-popover.popover .arrow,
.facing-tooltip.popover .arrow,
.facing-popover.popover .arrow,
.sensor-tooltip.popover .arrow,
.sensor-popover.popover .arrow {
  transform: rotate(-135deg);
  width: 10px;
  background: white;
  height: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  bottom: -6px;
  left: 50%;
  margin-left: -5px;
}

.small-circle {
  height: 10px;
  width: 10px;
  border-radius: 20px;
  margin-right: 8px;
}

.modal-fullscreen .modal-dialog {
  margin: 0;
  max-width: 100%;
}

.modal-fullscreen .modal-content {
  width: 100vw;
  height: 100vh;
}

[draggable] {
  user-select: none;
}

.sensor {}
.sensor.sensor-placed {}
.sensor.sensor-placed img {
  filter: grayscale(100%);
  opacity: .5;
}

.hvr-hang {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-hang:hover, .hvr-hang:focus, .hvr-hang:active {
  -webkit-animation-name: hvr-hang-sink, hvr-hang;
  animation-name: hvr-hang-sink, hvr-hang;
  -webkit-animation-duration: .3s, 1.5s;
  animation-duration: .3s, 1.5s;
  -webkit-animation-delay: 0s, .3s;
  animation-delay: 0s, .3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}

/* Hang */
@-webkit-keyframes hvr-hang {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes hvr-hang {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
}